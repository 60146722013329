
import { logOut } from '@/api/authModule';
import { flowDictionary } from '@/options/flowDictionary';
import { goToDashBoard } from '@/utils/redirectUtils';
import { getFilteredAppsList } from '@/api/appsModule';
import { finishFlow, setCurrentStage, startFlow } from '@/api/flowSession';
import { Component, Vue } from 'vue-property-decorator';
import { mapGetters, mapMutations, mapState } from 'vuex';
import BaseButton from '@/components/baseComponents/BaseButton.vue';
import IconBreadcrumb from '@/components/icons/IconBreadcrumb.vue'

@Component({
  components: { BaseButton, IconBreadcrumb },
  computed: {
    ...mapState('user', ['isPortalPageVisited']),
    ...mapGetters('user', ['isAuthenticated']),
    ...mapGetters('flowStages', ['getFlowName']),
  },
  methods: {
    ...mapMutations({
      setUserIdAndAccountId: 'user/setUserIdAndAccountId',
    }),
  },
})
export default class BaseMenuComponent extends Vue {
  public setUserIdAndAccountId!: (n: any) => void;
  public isPortalPageVisited!: boolean;
  public isAuthenticated!: boolean;
  public getFlowName!: string;

  showOptionsName = '';
  isMenuActive = false;
  landingLinks = [
    {
      title: 'Home',
      routeName: 'HomePage',
      class: 'home',
    },
    {
      title: 'Bank accounts',
      routeName: 'LandingCashManagementPage',
      class: 'cash-management',
    },
    {
      title: 'Credit score management',
      routeName: 'LandingCreditScorePage',
      class: 'credit-score',
    },
    {
      title: 'Business valuation',
      routeName: 'LandingValuationPage',
      class: 'business-value',
    },
    // {
    //   title: 'SMB funding',
    //   routeName: 'LandingLoanPage',
    //   class: 'funding',
    // },
    {
      title: 'Carbon footprint',
      routeName: 'LandingCarbonPage',
      class: 'carbon',
    },
    {
      title: 'Dashboard Pro',
      routeName: 'Landing360Page',
      class: 'business360',
    },
  ];
  aboutUsLinks = [
    {
      title: 'FAQ',
      routeName: 'FaqPage',
    },
    {
      title: 'Our mission',
      routeName: 'OurMissionPage',
    },
    {
      title: 'Pricing',
      routeName: 'PricingPage',
    },
    // {
    //   title: 'Our integrations',
    //   routeName: 'IntegrationsPage',
    // },
    // {
    //   title: 'Contact us',
    //   routeName: 'ContactUsPage',
    // },
  ];
  flowLinks = [
    {
      title: 'Cash management',
      class: 'cash-management',
      flowName: 'cash-management',
    },
    {
      title: 'Credit score management',
      class: 'credit-score',
      flowName: 'credit-score',
    },
    {
      title: 'Business valuation',
      class: 'business-value',
      flowName: 'business-valuation',
    },
    // {
    //   title: 'SMB funding',
    //   class: 'funding',
    //   flowName: 'business-loan',
    // },
    {
      title: 'Carbon footprint',
      class: 'carbon',
      flowName: 'carbon-footprint',
    },
    {
      title: 'Dashboard Pro',
      class: 'home',
      flowName: 'dashboard',
    },
  ];

  get isPortalPage(): boolean {
    return this.$route.name === 'PortalPage'
  }

  get companyName(): string {
    return this.$store.state.user.companyName;
  }
  get firstName(): string {
    return this.$store.state.user.firstName;
  }
  get lastName(): string {
    return this.$store.state.user.lastName;
  }
  get isShowLinks(): boolean {
    const forbiddenPages = ['LoginPage', 'RegistrationPage', 'ForgotPasswordPage', 'RestorePasswordPage', 'NamePage', 'PortalPage'];
    const routeName = this.$route.name as string;

    return !forbiddenPages.includes(routeName) || !this.isAuthenticated;
  }

  start(element: any): void {
    // eslint-disable-next-line
    element.style.height = `${element.scrollHeight}px`;
  }
  end(element: any): void {
    // eslint-disable-next-line
    element.style.height = '';
  }

  showOptions(optionsName = ''): void {
    if (this.showOptionsName === optionsName) this.showOptionsName = '';
    else this.showOptionsName = optionsName;
  }

  activeLandingLinkClass(routeName: string, className: string): string {
    if (this.$route.name === 'BusinessValuePage' && routeName === 'LandingValuationPage') return 'active-business-value';

    const activeClassName = `active-${className}`;
    return this.isLandingItemActive(routeName) ? activeClassName : '';
  }
  activeFlowLinkClass(flowName: string, className: string): string {
    const activeClassName = `active-${className}`;
    return this.isFlowItemActive(flowName) ? activeClassName : '';
  }
  isFlowItemActive(flowName: string): boolean {
    if (flowName === 'dashboard' && this.getFlowName === 'business360') return true;
    return flowName === this.getFlowName;
  }
  isLandingItemActive(routeName: string): boolean {
    return routeName === this.$route.name;
  }
  logoAction(): void {
    if (this.isAuthenticated) {
      this.$router.push({ name: 'PortalPage' });
    } else this.$router.push({ name: 'HomePage' });
    this.closeMenu();
  }
  // bookACall(): void {
  //   window.open('https://meetings.hubspot.com/kris-jeffay', '_blank');
  // }
  openMenu(): void {
    this.isMenuActive = true;
  }
  closeMenu(): void {
    this.isMenuActive = false;
  }

  async getStagesAndRedirectUser(flowName: string): Promise<void> {
    const flowVersion = flowDictionary[flowName];
    const currentFlow = this.$store.getters['flowStages/getCurrentFlow'];

    if (currentFlow) await finishFlow();

    if (!this.canRedirectUser()) {
      return;
    }

    switch (flowName) {
      case 'dashboard':
        await this.showDashboardFlow(flowVersion);
        break;
      case 'business-loan':
        await this.showBusinessLoanFlow(flowVersion);
        break;
      case 'credit-score':
        await this.showCreditScoreFlow(flowVersion);
        break;
      case 'cash-management':
        await this.showCashManagementFlow(flowVersion);
        break;
      case 'business-valuation':
        await this.showBusinessValuationFlow(flowVersion);
        break;
      case 'carbon-footprint':
        await this.showCarbonFootprintFlow(flowVersion);
        break;
      default:
        throw new Error(`Unhandled flow name: ${flowName}`);
    }

    this.closeMenu();
  }

  canRedirectUser(): boolean {
    return this.$store.getters['user/isRegistrationCompleted'];
  }

  async showDashboardFlow(flowVersion: string): Promise<void> {
    const isFlow302Comleted = this.$store.getters['flowStages/getIsFlowCompleted'](flowVersion);
    const { data: listApps } = await getFilteredAppsList({
      poolServiceInstanceId: '00d5c166-9911-45fc-8db4-79d94987ee99',
      isSharedOnly: true,
    });
    const isAnyAppShared = listApps.some((app: any) => app.isShared);

    if (isFlow302Comleted || isAnyAppShared) {
      goToDashBoard('dashboard');

    } else {
      const { data } = await startFlow(flowVersion);
      const { currentPage } = data;
      this.$store.commit('flowStages/setCurrentFlowAndStage', { flowVersion, pageName: 'AppsPage' });

      if (currentPage) {
        await setCurrentStage(currentPage);
        this.$router.push({ name: currentPage });
      } else {
        await setCurrentStage('AppsPage');
        this.$router.push({ name: 'AppsPage' });
      }
    }
  }

  async showBusinessLoanFlow(flowVersion: string): Promise<void> {
    const is107FlowCompleted = this.$store.getters['flowStages/getIsFlowCompleted'](flowVersion);

    if (is107FlowCompleted) {
      await startFlow('108');
      this.$store.commit('flowStages/setCurrentFlowAndStage', { flowVersion: '108' });
      this.$router.push({ name: 'AppStatusesPage' });
    } else {
      const { data } = await startFlow(flowVersion);
      const { currentPage } = data;
      if (currentPage) {
        await setCurrentStage(currentPage);
        this.$store.commit('flowStages/setCurrentFlowAndStage', { flowVersion: '107', pageName: currentPage });
        this.$router.push({ name: currentPage });
      } else {
        await setCurrentStage('AppsPage');
        this.$store.commit('flowStages/setCurrentFlowAndStage', { flowVersion: '107', pageName: 'AppsPage' });
        this.$router.push({ name: 'AppsPage' });
      }
    }
  }

  async showCreditScoreFlow(flowVersion: string): Promise<void> {
    const isFlow206Completed = this.$store.getters['flowStages/getIsFlowCompleted'](flowVersion);
    const { data: listApps } = await getFilteredAppsList({
      poolServiceInstanceId: '00d5c166-9911-45fc-8db4-79d94987ee99',
      isSharedOnly: true,
    });
    const isAnyAppShared = listApps.some((app: any) => app.isShared);

    if (isFlow206Completed || isAnyAppShared) {
      await startFlow('207');
      await setCurrentStage('CreditScorePage');
      this.$store.commit('flowStages/setCurrentFlowAndStage', { flowVersion: '207', pageName: 'CreditScorePage' });
      this.$router.push({ name: 'CreditScorePage' });
    } else {
      await startFlow(flowVersion);
      await setCurrentStage('AppsPage');
      this.$store.commit('flowStages/setCurrentFlowAndStage', { flowVersion, pageName: 'AppsPage' });
      this.$router.push({ name: 'AppsPage' });
    }
  }

  async showCashManagementFlow(flowVersion: string): Promise<void> {
    await startFlow(flowVersion);
    await setCurrentStage('CashManagementPage');
    this.$store.commit('flowStages/setCurrentFlowAndStage', { flowVersion: '600', pageName: 'CashManagementPage' });
    this.$router.push({ name: 'CashManagementPage' });
  }

  async showCarbonFootprintFlow(flowVersion: string): Promise<void> {
    await startFlow(flowVersion);
    await setCurrentStage('CarbonFootprintPage');
    this.$store.commit('flowStages/setCurrentFlowAndStage', { flowVersion: '700', pageName: 'CarbonFootprintPage' });
    this.$router.push({ name: 'CarbonFootprintPage' });
  }

  async showBusinessValuationFlow(flowVersion: string): Promise<void> {
    await startFlow(flowVersion);
    await setCurrentStage('BusinessValueResultsPage');
    this.$store.commit('flowStages/setCurrentFlowAndStage', { flowVersion: '508', pageName: 'BusinessValueResultsPage' });
    this.$router.push({ name: 'BusinessValueResultsPage' });
  }

  async logoutProcess(): Promise<void> {
    this.$store.commit('flowStages/resetFlowStages');

    logOut();

    this.isMenuActive = false;
    this.$router.push({ name: 'HomePage' });
  }
  popupError(errorText: string): void {
    this.$notify({
      group: 'main-notification',
      type: 'error',
      text: errorText,
    });
  }
}
