export const metaTitle = [
  {
    name: 'HomePage',
    text: {
      title: {
        100: 'CreditPush | The all-in-one digital platform to manage and grow your small business',
      },
      description: {
        100: "Improve your business's financial health, boost your business credit score, access quality funding and more - all in one CreditPush app.",
      },
    },
  },
  {
    name: 'FaqPage',
    text: {
      title: {
        100: 'Frequently asked questions | CreditPush',
      },
      description: {
        100: 'Need a personal walkthrough? Book a demo and we’ll show how this works and answer your questions.',
      },
    },
  },
  {
    name: 'PricingPage',
    text: {
      title: {
        100: 'Pricing | CreditPush',
      },
      description: {
        100: 'Start for Free during our beta-testing period with unlimited users and all features included. Designed for small businesses (‹25 employees, ‹$5 mln revenues).',
      },
    },
  },
  // {
  //   name: 'IntegrationsPage',
  //   text: {
  //     title: {
  //       100: 'Integrations | CreditPush',
  //     },
  //     description: {
  //       100: 'Our advanced embedded finance technology harnesses data from 200+ SaaS applications to empower your business.',
  //     },
  //   },
  // },
  {
    name: 'OurMissionPage',
    text: {
      title: {
        100: 'Our mission | CreditPush',
      },
      description: {
        100: 'We make it easy for small businesses to financially thrive. Interested? Be the first to get early access!',
      },
    },
  },
  // {
  //   name: 'ContactUsPage',
  //   text: {
  //     title: {
  //       100: 'Contacts | CreditPush',
  //     },
  //     description: {
  //       100: 'Reaching out for assistance or guidance with product support, privacy and security, and more.',
  //     },
  //   },
  // },
  {
    name: 'CreateAccountPage',
    text: {
      title: {
        500: 'CreditPush all-in-one platform for your business',
      },
      description: {
        100: 'CreditPush all-in-one platform for your business',
      },
    },
  },
  {
    name: 'RestorePasswordPage',
    text: {
      title: {
        100: 'CreditPush all-in-one platform for your business',
      },
      description: {
        100: 'CreditPush all-in-one platform for your business',
      },
    },
  },
  {
    name: 'BusinessValuePage',
    text: {
      title: {
        100: 'CreditPush all-in-one platform for your business',
      },
      description: {
        100: 'CreditPush all-in-one platform for your business',
      },
    },
  },
  {
    name: 'BusinessValueResultsPage',
    text: {
      title: {
        100: 'CreditPush all-in-one platform for your business',
      },
      description: {
        100: 'CreditPush all-in-one platform for your business',
      },
    },
  },
  {
    name: 'ForgotPasswordPage',
    text: {
      title: {
        100: 'CreditPush all-in-one platform for your business',
      },
      description: {
        100: 'CreditPush all-in-one platform for your business',
      },
    },
  },
  {
    name: 'LandingCreditScorePage',
    text: {
      title: {
        100: 'Credit score management | CreditPush',
      },
      description: {
        100: 'Check, monitor and boost your business credit score with CreditPush by simply connecting your existing business apps.',
      },
    },
  },
  {
    name: 'LandingLoanPage',
    text: {
      title: {
        100: 'Small business loans | CreditPush',
      },
      description: {
        100: 'Get better terms and pre-approved quality financing offers by sharing your data with lender through CreditPush solution.',
      },
    },
  },
  {
    name: 'LandingPaymentPage',
    text: {
      title: {
        100: 'CreditPush all-in-one platform for your business',
      },
      description: {
        100: 'CreditPush all-in-one platform for your business',
      },
    },
  },
  {
    name: 'Landing360Page',
    text: {
      title: {
        100: 'Small business dashboard | CreditPush',
      },
      description: {
        100: 'Helping you better monitoring the health of your business via actionable insights, cash flow forecasts, and simple to use budgeting tools.',
      },
    },
  },
  {
    name: 'LandingValuationPage',
    text: {
      title: {
        100: 'Business valuation | CreditPush',
      },
      description: {
        100: 'Calculate the value of your business using the multiple of earnings method and DCF model with our FREE business valuation calculator.',
      },
    },
  },
  {
    name: 'LandingCarbonPage',
    text: {
      title: {
        100: 'Carbon footprint | CreditPush',
      },
      description: {
        100: 'Become an eco-conscious business leader today. Carbon Footprint Calculator will make it easy for you to track your footprint and find ways to reduce it.',
      },
    },
  },
  {
    name: 'LandingCashManagementPage',
    text: {
      title: {
        100: 'Cash management | CreditPush',
      },
      description: {
        100: 'Get a complete view of your income and spending, receive alerts for upcoming payments and find opportunities to save money. A simple and convenient way to manage your finances in one place.',
      },
    },
  },
  {
    name: 'LoginPage',
    text: {
      title: {
        100: 'CreditPush all-in-one platform for your business',
      },
      description: {
        100: 'CreditPush all-in-one platform for your business',
      },
    },
  },
  {
    name: 'PolicyPage',
    text: {
      title: {
        100: 'Privacy policy | CreditPush',
      },
      description: {
        100: 'CreditPush all-in-one platform for your business',
      },
    },
  },
  {
    name: 'TermsOfUsePage',
    text: {
      title: {
        100: 'Terms of use | CreditPush',
      },
      description: {
        100: 'CreditPush all-in-one platform for your business',
      },
    },
  },
  {
    name: 'RegistrationPage',
    text: {
      title: {
        100: 'CreditPush - your trusted funding partner',
      },
      description: {
        100: 'CreditPush all-in-one platform for your business',
      },
    },
  },
  {
    name: 'NamePage',
    text: {
      title: {
        100: 'CreditPush all-in-one platform for your business',
      },
      description: {
        100: 'CreditPush all-in-one platform for your business',
      },
    },
  },
  {
    name: 'AppsPage',
    text: {
      title: {
        100: 'CreditPush all-in-one platform for your business',
      },
      description: {
        100: 'CreditPush all-in-one platform for your business',
      },
    },
  },
  {
    name: 'BureausPage',
    text: {
      title: {
        100: 'CreditPush all-in-one platform for your business',
      },
      description: {
        100: 'CreditPush all-in-one platform for your business',
      },
    },
  },
  {
    name: 'CreditScorePage',
    text: {
      title: {
        100: 'CreditPush all-in-one platform for your business',
      },
      description: {
        100: 'CreditPush all-in-one platform for your business',
      },
    },
  },
  {
    name: 'LendioPage',
    text: {
      title: {
        100: 'CreditPush all-in-one platform for your business',
      },
      description: {
        100: 'CreditPush all-in-one platform for your business',
      },
    },
  },
  {
    name: 'ThankYouPage',
    text: {
      title: {
        100: 'CreditPush all-in-one platform for your business',
      },
      description: {
        100: 'CreditPush all-in-one platform for your business',
      },
    },
  },
  {
    name: 'GetLoanPage',
    text: {
      title: {
        100: 'CreditPush all-in-one platform for your business',
      },
      description: {
        100: 'CreditPush all-in-one platform for your business',
      },
    },
  },
  {
    name: 'PortalPage',
    text: {
      title: {
        100: 'CreditPush all-in-one platform for your business',
      },
      description: {
        100: 'CreditPush all-in-one platform for your business',
      },
    },
  },
  {
    name: 'CashManagementPage',
    text: {
      title: {
        100: 'CreditPush all-in-one platform for your business',
      },
      description: {
        100: 'CreditPush all-in-one platform for your business',
      },
    },
  },
  {
    name: 'IndustryPage',
    text: {
      title: {
        100: 'CreditPush all-in-one platform for your business',
      },
      description: {
        100: 'CreditPush all-in-one platform for your business',
      },
    },
  },
];
