import { render, staticRenderFns } from "./TheCreditpushMainFooter.vue?vue&type=template&id=73a14873&scoped=true"
import script from "./TheCreditpushMainFooter.vue?vue&type=script&lang=ts"
export * from "./TheCreditpushMainFooter.vue?vue&type=script&lang=ts"
import style0 from "./TheCreditpushMainFooter.vue?vue&type=style&index=0&id=73a14873&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "73a14873",
  null
  
)

export default component.exports