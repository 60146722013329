
import { Vue, Component } from 'vue-property-decorator';
import BaseInputLanding from '@/components/landingAndRegistrationComponents/BaseInputLanding.vue';
import BaseButton from '@/components/baseComponents/BaseButton.vue';

@Component({
  components: { BaseInputLanding, BaseButton },
})
export default class TheCreditpushMainFooter extends Vue {
  goToPolicyPage(): void {
    if (this.$route.name !== 'PolicyPage') window.open('/policy', '_blank');
  }
  goToSocial(social: string): void {
    const socials: any = {
      facebook: 'https://www.facebook.com/creditpushcom',
      youtube: 'https://www.youtube.com/channel/UC9jaz1RjLuURNJbTCxsby-w',
      linkedin: 'https://www.linkedin.com/company/creditpush',
      twitter: 'https://twitter.com/infoinf83878811',
    };
    window.open(socials[social], '_blank');
  }
  goToLanding(): void {
    this.$router.push({ name: 'HomePage' });
  }
  // bookACall(): void {
  //   window.open('https://meetings.hubspot.com/kris-jeffay', '_blank');
  // }
}
