import { logIn } from '@/api/authModule';
import { instanceApi } from '@/api/instance';
import CreditpushMainLayout from '@/layouts/CreditpushMainLayout.vue';
import { IMetas } from '@/models';
import store from '@/store';
import { metaTitle } from '@/texts/metaTitleTexts';
import { trackFlow } from '@/utils/redirectUtils';
import ErrorPage from '@/views/creditpushMain/ErrorPage.vue';
import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router';
import { defineFlowVersion, defineUTM } from './utils';

Vue.use(VueRouter);

store.commit('initialiseStore');

if ('token-creditpush' in localStorage) {
  const token = localStorage.getItem('token-creditpush');
  instanceApi.defaults.headers.common.Authorization = `Bearer ${token}`;
}

const getQuerry = (to: Route, from: Route, next: NavigationGuardNext) => {
  defineUTM();
  defineFlowVersion(to);

  const { email } = to.query;
  if (email) store.commit('user/setEmail', email);
  next();
};

const restoreAccess = async (to: Route, from: Route, next: NavigationGuardNext): Promise<void> => {
  try {
    await logIn({
      email: to.query.email,
      code: to.query.code,
    });
    next();
  } catch (error: any) {
    next({ name: 'RegistrationPage', params: { error: error.response.data.message } });
  }
};

const userHasToken = () => 'token-creditpush' in localStorage;

const redirectToPreviousPath = (to: Route, from: Route, next: NavigationGuardNext): void => {
  const isAuthenticated = store.getters['user/isAuthenticated'];

  if (userHasToken() && isAuthenticated) {
    next({ name: 'PortalPage' });
  } else {
    next();
  }
};

const checkAccessAndToken = (routeName: string | null | undefined) => {
  const isStageAvailable = store.getters['flowStages/getAvailableStage'](routeName);

  return userHasToken() && isStageAvailable || routeName === 'PortalPage' && userHasToken() || routeName === 'NamePage' && userHasToken();
};

const checkPageAvailable = (to: Route, from: Route, next: NavigationGuardNext) => {
  if (checkAccessAndToken(to.name)) {
    next();
  } else {
    next({ name: 'HomePage' });
  }
};

const routes: Array<RouteConfig> = [
  {
    path: '/',
    component: CreditpushMainLayout,
    beforeEnter: getQuerry,
    children: [
      {
        path: '',
        name: 'HomePage',
        component: () => import('@/views/creditpushMain/HomePage.vue'),
        beforeEnter: redirectToPreviousPath,
      },
      {
        path: 'faq',
        name: 'FaqPage',
        component: () => import('@/views/creditpushMain/FaqPage.vue'),
      },
      // {
      //   path: 'contact-us',
      //   name: 'ContactUsPage',
      //   component: () => import('@/views/creditpushMain/ContactUsPage.vue'),
      // },
      {
        path: 'our-mission',
        name: 'OurMissionPage',
        component: () => import('@/views/creditpushMain/OurMissionPage.vue'),
      },
      {
        path: 'pricing',
        name: 'PricingPage',
        component: () => import('@/views/creditpushMain/PricingPage.vue'),
      },
      {
        path: 'policy',
        name: 'PolicyPage',
        component: () => import('@/views/creditpushMain/PolicyPage.vue'),
      },
      // {
      //   path: 'our-integrations',
      //   name: 'IntegrationsPage',
      //   component: () => import('@/views/creditpushMain/IntegrationsPage.vue'),
      // },
      {
        path: 'terms',
        name: 'TermsOfUsePage',
        component: () => import('@/views/creditpushMain/TermsOfUsePage.vue'),
      },
      {
        path: 'landing-credit-score',
        name: 'LandingCreditScorePage',
        component: () => import('@/views/landingAndRegistration/LandingCreditScorePage.vue'),
        beforeEnter: redirectToPreviousPath,
      },
      {
        path: 'landing-loan',
        name: 'LandingLoanPage',
        component: () => import('@/views/landingAndRegistration/LandingLoanPage.vue'),
        beforeEnter: redirectToPreviousPath,
      },
      {
        path: 'landing-payment-management',
        name: 'LandingPaymentPage',
        component: () => import('@/views/landingAndRegistration/LandingPaymentPage.vue'),
        beforeEnter: redirectToPreviousPath,
      },
      {
        path: 'landing-360',
        name: 'Landing360Page',
        component: () => import('@/views/landingAndRegistration/Landing360Page.vue'),
        beforeEnter: redirectToPreviousPath,
      },
      {
        path: 'landing-business-valuation',
        name: 'LandingValuationPage',
        component: () => import('@/views/landingAndRegistration/LandingValuationPage.vue'),
        beforeEnter: redirectToPreviousPath,
      },
      {
        path: 'landing-carbon-footprint',
        name: 'LandingCarbonPage',
        component: () => import('@/views/landingAndRegistration/LandingCarbonPage.vue'),
        beforeEnter: redirectToPreviousPath,
      },
      {
        path: 'landing-cash-management',
        name: 'LandingCashManagementPage',
        component: () => import('@/views/landingAndRegistration/LandingCashManagementPage.vue'),
        beforeEnter: redirectToPreviousPath,
      },
    ],
  },
  {
    path: '/',
    component: () => import('@/layouts/MainLayout.vue'),
    beforeEnter: getQuerry,
    children: [
      {
        path: 'login',
        name: 'LoginPage',
        component: () => import('@/views/landingAndRegistration/LoginPage.vue'),
      },
      {
        path: 'sign-up',
        name: 'RegistrationPage',
        component: () => import('@/views/landingAndRegistration/RegistrationPage.vue'),
      },
      {
        path: 'forgot-password',
        name: 'ForgotPasswordPage',
        component: () => import('@/views/landingAndRegistration/ForgotPasswordPage.vue'),
      },
      {
        path: 'restore-password',
        name: 'RestorePasswordPage',
        component: () => import('@/views/landingAndRegistration/RestorePasswordPage.vue'),
        beforeEnter: restoreAccess,
      },
      {
        path: 'portal',
        name: 'PortalPage',
        component: () => import('@/views/flow/PortalPage.vue'),
        beforeEnter: checkPageAvailable,
      },
      {
        path: 'name',
        name: 'NamePage',
        component: () => import('@/views/flow/NamePage.vue'),
        beforeEnter: checkPageAvailable,
      },
      {
        path: 'business-value',
        name: 'BusinessValuePage',
        component: () => import('@/views/flow/BusinessValuePage.vue'),
      },
      {
        path: 'carbon-footprint',
        name: 'CarbonFootprintPage',
        meta: {
          isNeedVerify: true,
        },
        component: () => import('@/views/flow/CarbonFootprintPage.vue'),
        beforeEnter: checkPageAvailable,
      },
      {
        path: 'business-valuation',
        name: 'BusinessValueResultsPage',
        meta: {
          isNeedVerify: true,
        },
        component: () => import('@/views/flow/BusinessValueResultsPage.vue'),
        beforeEnter: checkPageAvailable,
      },
      {
        path: 'industry',
        name: 'IndustryPage',
        meta: {
          isNeedVerify: true,
        },
        component: () => import('@/views/flow/IndustryPage.vue'),
        beforeEnter: checkPageAvailable,
      },
      {
        path: 'apps',
        name: 'AppsPage',
        meta: {
          isNeedVerify: true,
        },
        component: () => import('@/views/flow/AppsPage.vue'),
        beforeEnter: checkPageAvailable,
      },
      {
        path: 'bureaus',
        name: 'BureausPage',
        meta: {
          isNeedVerify: true,
        },
        component: () => import('@/views/flow/BureausPage.vue'),
        beforeEnter: checkPageAvailable,
      },
      {
        path: 'credit-score',
        name: 'CreditScorePage',
        meta: {
          isNeedVerify: true,
        },
        component: () => import('@/views/flow/CreditScorePage.vue'),
        beforeEnter: checkPageAvailable,
      },
      {
        path: 'lendio',
        name: 'LendioPage',
        meta: {
          isNeedVerify: true,
        },
        component: () => import('@/views/flow/LendioPage.vue'),
        beforeEnter: checkPageAvailable,
      },
      {
        path: 'app-statuses',
        name: 'AppStatusesPage',
        component: () => import('@/views/flow/AppStatusesPage.vue'),
        beforeEnter: checkPageAvailable,
      },
      {
        path: 'thanks',
        name: 'ThankYouPage',
        meta: {
          isNeedVerify: true,
        },
        component: () => import('@/views/flow/ThankYouPage.vue'),
        beforeEnter: checkPageAvailable,
      },
      {
        path: 'cash-management',
        name: 'CashManagementPage',
        meta: {
          isNeedVerify: true,
        },
        component: () => import('@/views/flow/CashManagementPage.vue'),
        beforeEnter: checkPageAvailable,
      },
      {
        path: 'get-loan',
        name: 'GetLoanPage',
        meta: {
          isNeedVerify: true,
        },
        component: () => import('@/views/flow/GetLoanPage.vue'),
        beforeEnter: checkPageAvailable,
      },
    ],
  },
  {
    path: '*',
    component: ErrorPage,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(): any {
    const app: any = document.querySelector('#app');
    app.scrollIntoView({ block: 'start' });
  },
});

router.beforeEach((to, from, next) => {
  next();
  const { name } = to;
  const meta = metaTitle.find((textObject: any) => textObject.name === name);
  if (meta) {
    const pageVersion = (store.state as any).steps.pageSettings[name || ''];
    const title = (meta as IMetas).text.title[pageVersion] || (meta as IMetas).text.title['100'];
    const description = (meta as IMetas).text.description[pageVersion] || (meta as IMetas).text.description['100'];

    document.title = title;
    const metaDescription = document.querySelector('meta[name="description"]');
    // eslint-disable-next-line
    metaDescription?.setAttribute('content', description);
  }

});

router.afterEach((to, from) => {
  store.commit('flowStages/setLastRouteName', from.name);
  trackFlow(to.name);
});

export default router;
