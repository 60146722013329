
import { Vue, Component } from 'vue-property-decorator';
import { mapGetters } from 'vuex';
import vClickOutside from 'v-click-outside';
import BaseButton from '@/components/baseComponents/BaseButton.vue';
import IconBreadcrumb from '@/components/icons/IconBreadcrumb.vue';
import IconArrow from '@/components/icons/IconArrow.vue';
import IconLock from '@/components/icons/IconLock.vue';
import IconProfile from '@/components/icons/IconProfile.vue';
import BaseMenuComponent from '@/components/baseComponents/BaseMenuComponent.vue';

@Component({
  components: {
    BaseButton,
    BaseMenuComponent,
    IconBreadcrumb,
    IconArrow,
    IconLock,
    IconProfile,
  },
  computed: {
    ...mapGetters('calculateWindowWidth', ['isDesktopSize']),
  },
})
export default class TheCreditpushMainHeader extends Vue {
  public isDesktopSize!: boolean;

  isHeaderBackgroundNotTransparent = false;
  showOptionsName = '';
  landingLinks = [
    {
      title: 'Bank accounts',
      linkTo: 'LandingCashManagementPage',
    },
    {
      title: 'Credit score management',
      linkTo: 'LandingCreditScorePage',
    },
    {
      title: 'Business valuation',
      linkTo: 'LandingValuationPage',
    },
    // {
    //   title: 'SMB funding',
    //   linkTo: 'LandingLoanPage',
    // },
    {
      title: 'Carbon footprint',
      linkTo: 'LandingCarbonPage',
    },
    {
      title: 'Dashboard Pro',
      linkTo: 'Landing360Page',
    },
  ];
  aboutUsLinks = [
    {
      title: 'FAQ',
      linkTo: 'FaqPage',
    },
    {
      title: 'Our mission',
      linkTo: 'OurMissionPage',
    },
    {
      title: 'Pricing',
      linkTo: 'PricingPage',
    },
    // {
    //   title: 'Our integrations',
    //   linkTo: 'IntegrationsPage',
    // },
    // {
    //   title: 'Contact us',
    //   linkTo: 'ContactUsPage',
    // },
  ];

  isNavItemActive(routeName: string): boolean {
    return routeName === this.$route.name;
  }
  start(element: any): void {
    // eslint-disable-next-line
    element.style.height = `${element.scrollHeight}px`;
  }
  end(element: any): void {
    // eslint-disable-next-line
    element.style.height = '';
  }
  handleScroll(): void {
    this.isHeaderBackgroundNotTransparent = window.scrollY > 30 || window.pageYOffset > 30;
  }
  showOptions(optionsName = ''): void {
    if (this.showOptionsName === optionsName) this.showOptionsName = '';
    else this.showOptionsName = optionsName;
  }
  goToLanding(): void {
    this.$router.push({ name: 'HomePage' });
  }
  goToLogin(): void {
    this.$router.push({ name: 'LoginPage' });
  }
  goToRegistration(): void {
    this.$router.push({ name: 'RegistrationPage' });
  }

  created(): void {
    Vue.use(vClickOutside);
  }
  mounted(): void {
    window.addEventListener('scroll', this.handleScroll);
  }
  beforeDestroy(): void {
    window.removeEventListener('scroll', this.handleScroll);
  }
}
